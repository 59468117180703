'use client';
import { LoadingIndicator } from '@dreipol/pusch-components';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { GlobalSettingsContext } from 'src/context/global-settings-context';
import { getGlobalSettings } from 'src/services/get-global-settings';
import { FALLBACK_LOCALE } from '../config';
import { Footer } from '../src/components/footer/Footer';
import { Layout } from '../src/components/layout/layout';
import { RecursiveContent } from '../src/components/recursive-content/recursive-content';
import { SeoTags } from '../src/components/seo-tags/seo-tags';
import { Stack } from '../src/components/stack/stack';
import { ArticleListContext } from '../src/context/article-list-context';
import { getArticles } from '../src/services/get-articles';
import { getFooter } from '../src/services/get-footer';
import { getHome } from '../src/services/get-home';
import { getPages } from '../src/services/get-pages';
import { groupFilters } from '../src/utils/get-filter-by-id';
import { getKeywordsIds } from '../src/services/get-keywords-ids';

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  query,
  preview,
}) => {
  const [rubricFilters, formatFilters, povFilters] = await groupFilters(
    locale ?? FALLBACK_LOCALE,
    (query.filterIds as string[]) ?? [],
  );

  const [pages, content, footer, globalSettings, articles] = await Promise.all([
    getPages(locale ?? FALLBACK_LOCALE, preview),
    getHome(locale ?? FALLBACK_LOCALE, preview),
    getFooter(locale ?? FALLBACK_LOCALE, preview),
    getGlobalSettings(locale ?? FALLBACK_LOCALE),
    getArticles({
      limit: parseInt((query.limit as string) ?? '5'),
      offset: parseInt((query.offset as string) ?? '0'),
      keywordIds: await getKeywordsIds(
        locale ?? FALLBACK_LOCALE,
        (query.q as string) ?? '',
        preview,
      ),
      authorIds: [''],
      formatFilterIds: formatFilters,
      rubricFilterIds: rubricFilters,
      povFilterIds: povFilters,
      term: (query.q as string) ?? '',
      locale: locale ?? FALLBACK_LOCALE,
    }),
  ]);

  return {
    props: {
      pages,
      content,
      footer,
      articles,
      globalSettings,
      ...(await serverSideTranslations(locale ?? 'de')),
    },
  };
};

export type PageProps = {
  /**
   * List of available pages
   */
  pages: Awaited<ReturnType<typeof getPages>>;
  content: Awaited<ReturnType<typeof getHome>>;
  articles: Awaited<ReturnType<typeof getArticles>>;
  footer: Awaited<ReturnType<typeof getFooter>>;
  globalSettings: Awaited<ReturnType<typeof getGlobalSettings>>;
};

export const MainPage = ({
  pages,
  content,
  footer,
  articles,
  globalSettings,
}: PageProps) => {
  const { isFallback } = useRouter();

  if (isFallback) {
    return <LoadingIndicator />;
  }

  return (
    <GlobalSettingsContext.Provider
      value={{
        glossarySlug: `/${globalSettings?.glossaryUrl.slug ?? ''}`,
        donationSlug: `/${globalSettings?.donationUrl.slug ?? ''}`,
        dataProtectionSlug: `/${globalSettings?.dataProtectionUrl.slug ?? ''}`,
      }}
    >
      <Layout
        pages={pages ?? []}
        footer={
          <>
            {footer && (
              <Footer
                companyName={footer.company}
                city={footer.city}
                companyDescription={footer.description}
                street={footer.street}
                addressAddition={footer.addressaddition}
                zip={footer.zip}
                phoneNumber={footer.phone}
                emailAddress={footer.mail}
                links={footer.links}
                legalLinks={footer.legalLinks}
                socialMediaLinks={footer.socialMediaLinks}
                contactFormLink={footer.contactForm}
              />
            )}
          </>
        }
      >
        <SeoTags tags={content?._seoMetaTags ?? []} />
        <ArticleListContext.Provider
          value={{
            articles: articles?.articles ?? [],
            count: articles?.count ?? 10,
          }}
        >
          <Stack space={6}>
            {content?.content && <RecursiveContent data={content.content} />}
          </Stack>
        </ArticleListContext.Provider>
      </Layout>
    </GlobalSettingsContext.Provider>
  );
};

export default MainPage;
